<template>
  <div class="geo-tab-page">
    <OCMap
      @update:draw="updateDraw"
      ref="map2"
      :draw="draw"
      :options="mapOptions"
      :zoom="17"
      class="map-block"
      @startMoving="movePointItem"
    >
      <MglMarker
        v-if="isPoint && !editing"
        :coordinates="value.geometry.coordinates"
        anchor="bottom"
        color="#7cb342"
        :scale="3"
      />
      <MglGeojsonLayer
        type="fill"
        v-if="!editing && isPolygon"
        :source="geometry"
        source-id="regionAGeoData"
        layer-id="regionGeoData"
        :layer="{
          id: 'regionGeoData',
          type: 'fill',
          paint: {
            'fill-color': '#44aa55',
            'fill-opacity': 0.5,
            'fill-outline-color': 'white'
          }
        }"

      />
      <MglGeojsonLayer
        :source="geometry"
        v-if="!editing"
        source-id="regionAGeoData"
        layer-id="regionGeoDataOutline"
        :layer="{
          id: 'regionGeoDataOutline',
          type: 'line',
          paint: {
            'line-color': '#44aa55',
            'line-width': 4
          }
        }"
      />

      <MglMarker
        v-for="item in editList" :key="item.id"
        anchor="bottom"
        :coordinates="item.geometry.coordinates"
        @drag="updateCoordinates(item, $event)"
        draggable
        :color="item.color"
      />

      <MglGeojsonLayer ref="linelayer2" source-id="editLinesData" :layer="{
          id: 'editLines2',
          type: 'line',
          paint: {
            'line-color': '#ffffff',
            'line-width': 6,
            'line-opacity': 0.6,
         },
          layout: {
            'line-cap': 'round'
          }
        }" layer-id="editLines2"  :source="editListLines" />

      <MglGeojsonLayer ref="linelayer" source-id="editLinesData" :layer="{
          id: 'editLines',
          type: 'line',
          paint: {
            'line-color': ['coalesce', ['get', 'color'], '#44aa55'],
            'line-width': 3,
            'line-dasharray': [4, 2]
          },
          layout: {
            'line-cap': 'round'
          }
        }"
       layer-id="editLines"/>


      <template v-slot:controls-top>
        <OCMapButton
          size-normal
          wide
          :to="(value.onModeration ? `/service/moderation/map?item=` : `/service/objects/map?item=`) + value.id"
          v-if="!editing"
          class="d-flex"
        >
          На большую карту
        </OCMapButton>
        <v-spacer />
        <OCMapEditSave :editList="editList" @update:editList="editList=[];editListInitialData={}" v-if="editList.length" />
        <OCMapSettings>
          <template v-slot:prepend>
            <v-list-item @click="showFeatures = !showFeatures">
              <v-list-item-content>
                Показать другие объекты
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon v-show="showFeatures">mdi-check</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider />
          </template>
        </OCMapSettings>
      </template>
      <template v-slot:controls-right>
        <v-spacer />
        <OCMapGeolocate />
        <OCMapZoom />
        <div class="my-4"></div>
      </template>
    </OCMap>
    <v-sheet class="pa-4 pa-md-6 rounded-b-lg">
      <v-label>
        Расположение (ближайший адрес):
        <div class="data-value mb-2">
          <template>
            <p v-if="!editing" v-text="value.location || '—'"></p>
            <v-text-field
              v-else
              class="my-2"
              dense
              outlined
              v-model="value.location"
              persistent-hint
              hint="При необходимости уточните значение вручную"
            ></v-text-field>
          </template>
        </div>
      </v-label>
      <v-checkbox v-model="replaceLocation" class="mt-0" v-if="editing && isPoint" label="Определять адрес автоматически" />
      <p v-if="value.parentObject">
        В составе объекта
        <router-link :to="`/service/objects/${value.parentObject.id}`"
          ><b>{{ value.parentObject.title }}</b></router-link
        >
      </p>
      <v-divider />
      <div class="d-flex flex-wrap py-4">
        <div class="col-6 col-md-3 pl-0 pr-2">
          <v-label v-if="isPoint">
            Широта:
            <div v-if="!editing" class="data-value" v-text="lat"></div>
            <v-text-field
              v-else
              v-model="lat"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-label>
        </div>
        <div class="col-6 col-md-3 pl-0 pr-2">
          <v-label v-if="isPoint">
            Долгота:
            <div v-if="!editing" class="data-value" v-text="lng"></div>
            <v-text-field
              v-else
              v-model="lng"
              dense
              hide-details
              outlined
            ></v-text-field>
          </v-label>
        </div>
        <div class="col-12 col-md-6 px-0">
          <v-label>
            Регион:
            <div class="data-value" v-text="regionName"></div>
          </v-label>
        </div>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import opencity from "../../service/opencity";

import {MglGeojsonLayer, MglMarker} from "vue-mapbox";
import OCMap from "@/components/map/OCMap";
import OCMapSettings from "@/components/map/OCMapSettings";
import OCMapButton from "@/components/map/OCMapButton";
import OCMapGeolocate from "@/components/map/OCMapGeolocate";
import OCMapZoom from "@/components/map/OCMapZoom";
import OCMapEditSave from "@/views/Object/OCMapEditSave.vue";

export default {
  props: {
    editing: {
      type: Boolean
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      showFeatures: false,
      selection: 0,
      mapStyle: opencity.getDefaultMapStyle(),
      replaceLocation: false,
      draw: null,
      editList: [],
      editListInitialData: {}
    };
  },
  computed: {
    editListLines() {
      let geojson = {
        type: "FeatureCollection",
        features:
          this.editList.map(el => {
            return {
              type: "Feature",
              properties: {
                color: el.color
              },
              geometry: {
                type: "LineString",
                coordinates: [this.editListInitialData[el.id], el.geometry.coordinates]
              }
            }
          })
      };

      return {data: geojson}
    },
    mapOptions() {
      return {
        hideFeatures: !this.showFeatures,
        exclude: [this.value.id],
        showUnmoderated: true,
        editPoints: true
      };
    },
    isPoint() {
      return this.value.geometry.type === "Point";
    },
    isPolygon() {
      return this.value.geometry.type === "MultiPolygon";
    },
    lat: {
      get() {
        return this.value.geometry.coordinates[1];
      },
      set(val) {
        this.value.geometry.coordinates = [this.lng, val];
      }
    },
    lng: {
      get() {
        return this.value.geometry.coordinates[0];
      },
      set(val) {
        this.value.geometry.coordinates = [val, this.lat];
      }
    },
    teamId() {
      return this.$route.params.id;
    },
    regionName() {
      return this.value.region?.name;
    },
    geometry() {
      return {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: this.value.geometry
        }
      };
    }
  },
  mounted() {
    this.$refs.map2.focusItem(this.value, { animate: false });
  },
  methods: {
    updateCoordinates(item, event) {
      let lngLat = event.marker.getLngLat();
      item.geometry.coordinates = [lngLat.lng, lngLat.lat]
    },
    reorderLayers() {
      debugger
      this.$refs.linelayer?.move();
    },
    movePointItem(item) {
      if (this.editListInitialData[item.id]) {
        return;
      }
      this.editListInitialData[item.id] = item.geometry.coordinates;
      this.editList.push(item);
    },
    updateDraw(draw) {
      this.value.geometry = draw.geometry;
      if (this.isPoint) {
        const latLng = {
          lat: draw.geometry.coordinates[1],
          lng: draw.geometry.coordinates[0]
        };
        latLng.lat = Math.round(latLng.lat * 1000000) / 1000000;
        latLng.lng = Math.round(latLng.lng * 1000000) / 1000000;
        this.value.geometry.coordinates = [latLng.lng, latLng.lat];
        if (this.replaceLocation)
          this.fetchLocSuggestion(latLng.lat, latLng.lng);
        opencity
          .probeObjects(latLng.lat, latLng.lng)
          .then(res => (this.value.parentObject = res[0]));
        opencity
          .probeRegions(latLng.lat, latLng.lng)
          .then(res => (this.value.region = res.find(el => el.parentRegion)));
      }
    },
    async fetchLocSuggestion(lat, lng) {
      const data = await fetch(
        `https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + process.env.VUE_APP_DADATA_KEY
          },
          body: JSON.stringify({ lat: lat, lon: lng, count: 1 })
        }
      ).then(response => response.json());

      this.value.location = data?.suggestions[0].value.replace("г Пермь, ", "");
    },
  },
  watch: {
    editing: {
      immediate: true,
      handler(val) {
        const type = this.value.geometry.type;
        if (type !== "MultiPolygon" && type !== "Polygon" && type !== "Point")
          return;
        if (val) {
          this.draw = {
            config: {
              type: this.value.geometry.type === "Point" ? "Point" : "Polygon",
              edit: true
            },
            geometry: this.value.geometry
          };
        }
      }
    }
  },
  components: {
    OCMapEditSave,
    OCMapZoom,
    OCMapGeolocate,
    OCMapButton,
    OCMapSettings,
    OCMap,
    MglGeojsonLayer,
    MglMarker
  }
};
</script>

<style lang="scss">
.choice-btn-active.v-btn--disabled .v-btn__content {
  color: black;
}
.map-block .mapboxgl-map {
  position: relative;
}

.map-block {
  @media (min-width: 960px) {
    height: 460px;
  }
  height: 350px;
}

.mapboxgl-canvas-container {
  height: 400px;
  //  overflow: hidden;
}

.data-value {
  text-overflow: ellipsis;
}

.geo-tab-page .mapboxgl-ctrl-geolocate {
  display: none;
}
</style>
