import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","left":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('OCMapButton',_vm._g({attrs:{"loading":_vm.loading,"wide":"","size-normal":""}},on),[_vm._v(" Изменено "+_vm._s(_vm.editList.length)+" объектов "),_c(VIcon,{staticClass:"mr-n2"},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c(VList,[_c(VListItem,{on:{"click":_vm.save}},[_vm._v(" Сохранить ")]),_c(VListItem,{on:{"click":_vm.undo}},[_vm._v(" Отмена ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }