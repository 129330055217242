<template>
  <div class="info-page">
    <div style="height: 100%; --teams-top: 0" class="d-flex flex-column team">
      <!-- <div class="elevation-1" style="z-index:1"> -->
      <v-sheet style="z-index: 4">
        <v-container fluid class="head px-3 mb-1" style="max-width: 960px">
          <v-row>
            <v-col cols="12" class="d-flex align-center">
              <TextButton
                v-if="!backRoute"
                to="/service/objects/"
                :ripple="false"
                exact
                text
                opacity="0.5"
                class="px-2 ml-n2 style text-capitalize font-weight-regular"
              >
                <v-icon class="pr-1" size="20">mdi-format-list-numbered</v-icon>
                Объекты
              </TextButton>
              <TextButton
                v-else
                :to="backRoute.route"
                :ripple="false"
                exact
                text
                opacity="0.5"
                class="px-2 ml-n2 style text-capitalize font-weight-regular"
              >
                <v-icon class="pr-1" size="20">mdi-keyboard-backspace</v-icon>
                {{ backRoute.title }}
              </TextButton>
              <v-spacer />
              <TextButton
                @click="saveToDialog = true"
                :opacity="itemSaved ? 1 : 0.5"
                icon
              >
                <v-icon
                  :color="itemSaved && 'amber darken-1'"
                  class="secondary-button mx-2"
                  tabindex="0"
                >
                  {{ itemSaved ? "mdi-bookmark" : "mdi-bookmark-outline" }}
                </v-icon>
              </TextButton>
              <span class="text--disabled">#{{ teamId }}</span>
            </v-col>
            <div class="text-h5 font-weight-bold text-center w-100">
              {{ team ? team.title : "Объект" }}
            </div>
          </v-row>
          <v-row v-if="team && team.deactivatedAt">
            <v-col>
              <v-alert icon="mdi-trash-can" type="error" class="w-100 ma-0"
                >Объект был удален {{ formatDate(team.deactivatedAt) }}</v-alert
              >
            </v-col>
          </v-row>
          <v-row v-if="team && team.onModeration">
            <v-col>
              <v-alert
                icon="mdi-information-outline"
                type="warning"
                class="w-100 ma-0"
              >
                <div class="d-flex align-start flex-wrap">
                  <span class=""> Объект находится <b>на модерации</b> </span>
                  <v-spacer />
                  <v-btn
                    @click="publish(true)"
                    class="my-2 my-md-0"
                    depressed
                    light
                    >Опубликовать</v-btn
                  >
                </div>
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>

      <div ref="scrollTo"></div>
      <v-sheet style="z-index: 3" elevation="2" class="sticky">
        <v-tabs style="max-width: 960px" class="ma-auto">
          <!-- <v-tab class="pa-0 ma-0" style="min-width: 0px" /> -->
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-tab
              :to="tab.href"
              exact
              v-for="tab in tabs"
              :key="tab.href"
              v-text="tab.name"
            />
          </template>

          <v-menu offset-y v-else>
            <template v-slot:activator="{ on, attrs }">
              <div class="v-tab body-1" v-on="on" v-bind="attrs">
                {{ currentTab.name }}
                <v-icon size="24" class="mr-n2"> mdi-menu-down </v-icon>
              </div>
            </template>
            <v-list>
              <v-list-item
                exact
                v-for="tab in tabs"
                v-text="tab.name"
                :key="tab.href"
                :to="tab.href"
              ></v-list-item>
            </v-list>
          </v-menu>
          <v-spacer />
          <div
            class="d-flex align-center pr-1"
            v-if="!editing && (loading || !team.deactivatedAt)"
          >
            <v-btn
              @click="editing = true"
              class="mr-2 pa-0"
              color="primary"
              x-small
              width="40"
              height="40"
              outlined
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  depressed
                  color="primary"
                  class="font-weight-regular"
                  height="40"
                >
                  Действия
                  <v-icon size="24" class="mr-n2"> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="saveToDialog = true">
                  Добавить в список
                </v-list-item>
                <v-list-item v-if="!loading && isPolygon" :href="reportUrl"
                  >Экспорт входящих объектов</v-list-item
                >
                <v-list-item
                  v-if="!loading && !team.onModeration"
                  @click="publish(false)"
                  >Снять с публикации</v-list-item
                >
                <v-list-item @click="deleteDialog = true" link>
                  Удалить
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div v-if="editing" class="d-flex align-center pr-1">
            <v-btn @click="cancel" class="font-weight-regular mx-2" depressed>
              Отмена
            </v-btn>
            <v-btn
              @click="save"
              class="font-weight-regular"
              color="primary"
              depressed
            >
              Сохранить
            </v-btn>
          </div>
        </v-tabs>
        <v-progress-linear
          height="4"
          :active="loading"
          indeterminate
          absolute
          bottom
        ></v-progress-linear>
      </v-sheet>
      <!-- </div> -->

      <div
        :style="$vuetify.theme.isDark || 'background:rgb(244 245 247)'"
        style="min-height: 100vh"
      >
        <v-container class="pt-4" style="max-width: 960px">
          <component
            v-if="!loading"
            v-model="team"
            :editing="editing"
            :is="tabComponent"
          />
        </v-container>
      </div>
    </div>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Подтверждение
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить объект?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text @click="deleteDialog = false">
            Отмена
          </v-btn>
          <v-btn color="red darken-1" text @click="deleteObject">
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SaveToDialog @save="onListsUpdate" :items="teams" v-model="saveToDialog" />
  </div>
</template>

<script>
import PanelFrame from "@/components/PanelFrame";

import ProfileTab from "./ProfileTab";
import MediaTab from "./MediaTab";
import GeoTab from "./GeoTab";
import ParamTab from "./ParamTab";

import DisabledTab from "@/components/DisabledTab";

import opencity from "../../service/opencity";
import SaveToDialog from "@/components/object/SaveToDialog.vue";

import { datetime2 } from "@/utils/datetime";
import TextButton from "@/views/Objects/NewObject/TextButton";

export default {
  components: {
    TextButton,
    SaveToDialog,
    PanelFrame,
    ProfileTab,
    MediaTab,
    DisabledTab
  },
  data() {
    return {
      editing: false,
      selection: 0,
      n: Math.floor(Math.random(500)),
      selectedTab: 0,
      team: null,
      loading: true,
      deleteDialog: false,
      backRoute: null,
      saveToDialog: false,
      itemSaved: false
    };
  },
  computed: {
    isPolygon() {
      return this.team.geometry.type === "MultiPolygon";
    },
    teams() {
      return [this.team];
    },
    reportUrl() {
      return opencity.API_BASE + `/objects/${this.teamId}/reports/1`;
    },
    teamId() {
      return this.$route.params.id;
    },
    activeTab: {
      set(val) {
        if (val === 4) return;
        this.selectedTab = val;
      },
      get() {
        if (this.overflowSelected) return 4;
        return this.selectedTab;
      }
    },
    overflowSelected() {
      return this.selectedTab > 3;
    },
    tabComponent() {
      return this.currentTab?.component || DisabledTab;
    },
    currentTab() {
      const { hash } = this.$route;
      if (hash && hash !== "#") {
        return this.tabs.find(el => el.href === hash);
      } else {
        return this.tabs[0];
      }
    },
    tabs() {
      return [
        {
          name: "Профиль",
          href: this.$route.fullPath.split("#")[0],
          component: ProfileTab
        },
        {
          name: "Медиа",
          href: "#media",
          component: MediaTab
        },
        {
          name: "Параметры",
          href: "#params",
          component: ParamTab
        },
        {
          name: "Геоданные",
          href: "#geo",
          component: GeoTab
        },
        {
          name: "История",
          href: "#insights",
          component: null
        }
      ];
    }
  },
  methods: {
    onListsUpdate(data) {
      this.itemSaved = data.some(el => el.allIncluded);
    },
    formatDate(date) {
      return datetime2(date);
    },
    async fetchMain() {
      this.loading = true;
      this.team = await opencity.getObject(this.$route.params.id);
      opencity
        .get("/lists?objectId=" + this.$route.params.id)
        .then(res => res.json())
        .then(data => {
          this.onListsUpdate(data);
        });
      this.loading = false;
    },
    async save() {
      console.log(this.team);
      opencity.postObject(this.team);
      this.editing = false;
    },
    cancel() {
      this.editing = false;
      this.fetchMain();
    },
    async publish(value = true) {
      this.team.onModeration = !value;
      await this.save();
    },
    async deleteObject() {
      this.deleteDialog = false;
      try {
        let res = await opencity.deleteObject(this.teamId);
        if (res.status === 200) {
          this.$router.push("/service/objects");
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  created() {
    this.$emit("update:layout", PanelFrame);
    this.fetchMain();
  },
  watch: {
    $route(newV, oldV) {
      if (oldV.params.id !== newV.params.id) this.fetchMain();
      else {
        if (oldV.hash !== newV.hash)
          this.$refs.scrollTo.scrollIntoView({ block: "nearest" });
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      switch (from.name) {
        case "Objects":
          vm.backRoute = { title: "Объекты", route: from };
          break;
        case "ObjectsMap":
          vm.backRoute = { title: "Карта", route: from };
          break;
      }
    });
  }
};
</script>

<style lang="scss">
.show-on-hover {
  opacity: 0;
  transition: opacity ease-in 0.2s;
}

.card-grid-card:hover .show-on-hover {
  opacity: 1;
}

.card-grid-card {
  border-radius: 8px;
}

.team .theme--light.v-input--is-disabled input {
  color: rgba(0, 0, 0, 0.87);
}

.team .theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87);
}

.team .theme--light.v-input--is-disabled {
  color: rgba(0, 0, 0, 0.87);
}

.card-grid-wrapper {
  // max-width: 1080px;
  // margin: auto;
  margin: -6px;
}

.col.card-grid {
  max-width: 520px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  padding: 0;
  & > * {
    margin: 6px;
  }
}

.info-page .v-tab {
  text-transform: none;
  letter-spacing: normal;
  font-weight: 400;
}
</style>
