<template>
  <v-menu offset-y left nudge-bottom="8">
    <template v-slot:activator="{ on }">
      <OCMapButton :loading="loading" v-on="on" wide size-normal>
        Изменено {{ editList.length }} объектов
        <v-icon class="mr-n2">mdi-chevron-down</v-icon>
      </OCMapButton>
    </template>
    <v-list>
      <v-list-item @click="save">
        Сохранить
      </v-list-item>
      <VListItem @click="undo">
        Отмена
      </VListItem>
    </v-list>
  </v-menu>
</template>
<script>
import OCMapButton from "@/components/map/OCMapButton"
import opencity from "@/service/opencity";

export default {
  name: 'OCMapEditSave',
  components: {OCMapButton},
  data: () => ({
    loading: false
  }),
  props: {
    editList: {}
  },
  methods: {
    undo() {
      this.$emit('update:editList', [])
    },
    save() {
      if (this.loading) return;
      this.loading = true
      Promise.all(this.editList.map(el => opencity.postObject(el))).then(() => location.reload()).finally(() => this.loading = false);
    }
  }
}
</script>
