<template>
  <v-row class="justify-center">
    <v-col class="card-grid">
      <v-sheet elevation="1" class="card-grid-card">
        <v-card-title class="d-flex">
          <span>Основные данные</span>
        </v-card-title>
        <v-card-text>
          <v-label>
            Название:
            <div v-if="!editing" class="data-value" v-text="value.title" />
            <v-text-field v-else v-model="value.title" outlined dense />
          </v-label>
          <v-label>
            Расположение:
            <div class="data-value">
              <p v-text="value.location || '—'"></p>
            </div>
            <router-link v-if="staticImgSrc" to="#geo">
              <v-img
                class="my-4 grey lighten-2 rounded"
                :src="staticImgSrc"
                height="220"
              >
              </v-img>
            </router-link>
            <v-btn class="my-4" color="primary" v-else to="#geo" text block>Показать фигуру</v-btn>
            <div class="data-value" v-if="value.parentObject">
              В составе объекта
              <router-link :to="`/service/objects/${value.parentObject.id}`"
                ><b>{{ value.parentObject.title }}</b></router-link
              >
            </div>
          </v-label>
          <v-label>
            Категория / Тип объекта:
            <div class="data-value">
              {{ category.title }} / {{ value.objectType.title }}
            </div>
          </v-label>
          <v-label>
            Статус:
            <div class="data-value" v-text="value.onModeration ? 'На модерации' : 'Опубликован'"></div>
          </v-label>
        </v-card-text>
      </v-sheet>
      <v-sheet class="card-grid-card" elevation="1">
        <v-card-title>Параметры</v-card-title>
        <v-card-text>
          <v-label v-for="p of properties.values" :key="p.value">
            {{ p.name }}:
            <p class=" data-value" v-text="p.value" />
          </v-label>
          <div v-if="properties.empty > 0" class="text-center text--secondary">
            +{{properties.empty}} пустых
          </div>
          <div class="d-flex justify-center">
            <v-btn block text color="primary" to="#params">Подробнее</v-btn>
          </div>
        </v-card-text>
      </v-sheet>
    </v-col>
    <v-col class="card-grid">
      <v-sheet class="card-grid-card" elevation="1">
        <v-card-title>Детали</v-card-title>
        <v-card-text>
          <router-link to="#media">
            <OCMediaPreview disable-gallery :media="value.media" :height="220" />
          </router-link>
          <v-label>
            Примечание:
            <ExpandableParagraph v-if="!editing" :text="value.note || '—'" />
            <v-textarea
              v-else
              v-model="value.note"
              outlined
              dense
              rows="3"
              auto-grow
            ></v-textarea>
          </v-label>
          <v-label>
            Обслуживающая организация:
            <div
                v-if="!editing"
                class="data-value"
                v-text="value.data.responsible_team_text || '—'"
            ></div>
            <v-autocomplete
                clearable
                v-else
                item-text="label"
                item-value="value"
                v-model="value.data.responsible_team_text"
                dense
                outlined
                :items="[
                  'МКУ «ГорЗеленСтрой»',
                ]"
            >
            </v-autocomplete>
          </v-label>
          <v-label>
            Видимость:
            <div
              v-if="!editing"
              class="data-value"
              v-text="value.visible ? 'Для всех' : 'Для команды'"
            ></div>
            <v-select
              v-else
              item-text="label"
              item-value="value"
              v-model="value.visible"
              dense
              outlined
              :items="[
                { value: true, label: 'Для всех' },
                { value: false, label: 'Для команды' }
              ]"
            >
            </v-select>
          </v-label>
        </v-card-text>
      </v-sheet>
      <v-sheet class="card-grid-card" elevation="1">
        <v-card-title
          >Активность</v-card-title
        >
        <v-card-text>
          <div class="activity-link">Работы (0)</div>
          <div class="activity-link">Сообщения жителей (0)</div>
          <div class="activity-link">Мероприятия (0)</div>
        </v-card-text>
      </v-sheet>
      <v-sheet class="card-grid-card" v-if="value.objectType.id === 1" elevation="1">
        <v-card-title>Экологическая польза <v-icon right>mdi-sign-caution</v-icon></v-card-title>
        <v-card-text>
          <div class="rounded-lg mb-3 overflow-hidden">
            <v-list-item class="d-flex text-body-1 align-start pa-1" @click="showEco = !showEco">
              <v-icon class="mr-2" color="success">mdi-currency-rub</v-icon>
              <div>
                <div class="font-weight-medium">Общая ежегодная ценность</div>
                <div>6 500 руб.</div>
              </div>
              <v-spacer />
              <v-icon large>{{
                showEco ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-list-item>
          </div>
          <v-expand-transition>
            <div v-if="showEco">
              <v-divider />
              <v-list-item class="my-3 d-flex text-body-1 align-start pa-1" @click="">
                <v-icon class="mr-2" color="success">mdi-weather-rainy</v-icon>
                <div class="flex-grow-1">
                  <div class="font-weight-medium">Удержание ливневой воды, в год</div>
                  <div class="row">
                    <div class="col-12 col-md-6">4 190 л</div>
                    <div class="col-12 col-md-6">Ценность: 770 руб.</div>
                  </div>
                </div>
              </v-list-item>
              <v-list-item class="my-3 d-flex text-body-1 align-start pa-1" @click="">
                <v-icon class="mr-2" color="success">mdi-lightning-bolt-outline</v-icon>
                <div class="flex-grow-1">
                  <div class="font-weight-medium">Экономия энергии, в год</div>
                  <div class="row">
                    <div class="col-12 col-md-6">950 кВт</div>
                    <div class="col-12 col-md-6">Ценность: 5 320 руб.</div>
                  </div>
                </div>
              </v-list-item>
              <v-list-item class="my-3 d-flex text-body-1 align-start pa-1" @click="">
                <v-icon class="mr-2" color="success">mdi-cloud-outline</v-icon>
                <div class="flex-grow-1">
                  <div class="font-weight-medium">Удаление загрязняющих частиц из воздуха, в год</div>
                  <div class="row">
                    <div class="col-12 col-md-6">9 кг</div>
                    <div class="col-12 col-md-6">Ценность: 630 руб.</div>
                  </div>
                </div>
              </v-list-item>
              <v-list-item class="my-3 d-flex text-body-1 align-start pa-1" @click="">
                <v-icon class="mr-2" color="success">mdi-web</v-icon>
                <div class="flex-grow-1">
                  <div class="font-weight-medium">Поглощение углекислого газа, в год</div>
                  <div class="row">
                    <div class="col-12 col-md-6">724 кг</div>
                    <div class="col-12 col-md-6">Ценность: 135 руб.</div>
                  </div>
                </div>
              </v-list-item>
            </div>
          </v-expand-transition>
        </v-card-text>
      </v-sheet>
      <v-sheet class="card-grid-card" elevation="1">
        <v-card-text>
          <template v-if="value.author">
            <v-label>Автор</v-label>
            <div class="mb-3">
              <v-btn text class="ml-n2 px-2 py-2">
                <v-avatar class="mr-2" color="teal" size="28">
                  <span class="white--text header">{{
                    value.author.firstName[0]
                  }}</span>
                </v-avatar>
                <span class="body-1">
                  {{ value.author.fullName }}
                </span>
              </v-btn>
            </div>
          </template>
          <v-label> Дата создания </v-label>
          <div class="mb-3">
            <span class="body-1 mb-4" :title="fulldatetime(value.createdAt)" tabindex="0">
              {{ datetime2(value.createdAt) }}
            </span>
          </div>
          <v-label> Дата изменения </v-label>
          <div class="mb-1">
            <span class="body-1" :title="fulldatetime(value.createdAt)" tabindex="0">
              {{ datetime2(value.updatedAt) }}
            </span>
          </div>
        </v-card-text>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import {datetime2, fulldatetime} from "@/utils/datetime";

import opencity, {toText} from "../../service/opencity";
import ExpandableParagraph from "@/components/ExpandableParagraph.vue";
import NoPhoto from "@/components/NoPhoto.vue";
import OCMediaPreview from "@/components/object/OCMediaPreview";
import useClassifier from "@/mixins/useClassifier";

export default {
  components: {OCMediaPreview, NoPhoto, ExpandableParagraph},
  props: {
    value: {
      type: Object
    },
    editing: {
      type: Boolean
    }
  },
  data() {
    return {
      selection: 0,
      showEco: false
    };
  },
  methods: {
    datetime2, fulldatetime
  },
  mixins: [useClassifier],
  computed: {
    teamId() {
      return this.$route.params.id;
    },
    staticImgSrc() {
      return opencity.getStaticMapFeatureURL(this.value);
    },
    category() {
      return this.OCClassifier.find(el =>
        el.objectTypes.find(el => el.id === this.value.objectType.id)
      );
    },
    properties() {
      if (!this.value) return null;
      const type = opencity.getObjectType(this.value.objectType.id);
      if (!type) return null;
      const props = this.value.data;
      const res = [];
      for (let field of type.schema.fields) {
        if (!props[field.key]) continue;
        res.push({
          name: field.name,
          value: toText(field.type, props[field.key])
        });
      }
      return {
        empty: type.schema.fields.length - res.length,
        values: res
      };
    }
  },
};

// :to="`/user/${value.author.id}`"
</script>

<style lang="scss">
.choice-btn-active.v-btn--disabled .v-btn__content {
  color: black;
}

.map-block .mapboxgl-map {
  position: relative;
}

.mapboxgl-canvas-container {
  height: 400px;
}

.v-label {
  font-size: 0.825rem;
  .data-value {
    line-height: normal;
    white-space: pre-line;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 1rem;
    .theme--dark & {
      color: #fff;
    }
  }
}

.activity-link {
  text-decoration: none;
  margin-bottom: 4px;
  display: block;
  font-size: 0.925rem;
}

.static-marker {
  position: absolute;
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  background: green;
  height: 16px;
  border-radius: 100%;
  border: 2px solid white;
}

.v-label textarea {
  max-height: 260px;
  overflow-y: auto;
}

.v-slide-group__prev,
.v-slide-group__next {
  display: none !important;
}

.w-50 {
  width: 50%;
}
</style>
